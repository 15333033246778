import { call, put } from 'redux-saga/effects';
import { OrderActions, UIActions } from '../actions/index';
import { OrderService } from '../services/order.service';

export function* createOrder(action: ReturnType<typeof OrderActions.createOrder>): any {
	try {
		yield put(UIActions.showLoader(true));

		const { data } = yield call(OrderService.getInstance().createOrder, action.payload.order);

		if (data) {
			yield put(OrderActions.setOrderDetails(data[0]));
			// TODO returning the first element in this array for now. Multi shipments will return multiple orders and the track order page will need updating to handle that
			action.payload.callback(data[0]);
		}
		yield put(UIActions.showLoader(false));
	} catch (err: any) {
		console.log('createOrder saga error', err);
		yield put(
			UIActions.setSnackbarMessage({
				message: 'Failed to create order: ' + err.message,
				type: 'error',
			}),
		);
		yield put(UIActions.showLoader(false));
	}
}

export function* getOrderDetails(action: ReturnType<typeof OrderActions.getOrderDetails>): any {
	try {
		yield put(UIActions.showLoader(true));

		const { data } = yield call(OrderService.getInstance().getOrderDetails, action.payload.filter);
		if (data && data.length > 0) {
			yield put(OrderActions.setOrderDetails(data[0]));
			action.payload.callback();
		} else {
			yield put(
				UIActions.setSnackbarMessage({
					message: 'Order Number does not exist',
					type: 'error',
				}),
			);
		}
		yield put(UIActions.showLoader(false));
	} catch (err: any) {
		console.log('getOrderDetails saga error', err);
		yield put(
			UIActions.setSnackbarMessage({
				message: 'Failed to get order details: ' + err.message,
				type: 'error',
			}),
		);
		yield put(UIActions.showLoader(false));
	}
}

export function* getTaxRate(action: ReturnType<typeof OrderActions.getTaxRate>): any {
	try {
		yield put(OrderActions.showTaxLoader(true));
		const { data } = yield call(OrderService.getInstance().getTaxRate, action.payload.taxPayload);
		if (data) {
			yield put(OrderActions.setTaxRate(data));
			yield put(OrderActions.setTaxRateLoaded(true));
			action.payload.callback();
		}
		yield put(OrderActions.showTaxLoader(false));
	} catch (err: any) {
		console.log('getTaxRate saga error', err);
		const message = err.response.status === 460 ? 'Conflict between State and Zip Code' : err.message;
		yield put(
			UIActions.setSnackbarMessage({
				message: 'Failed to get tax rate: ' + message,
				type: 'error',
			}),
		);
		yield put(OrderActions.setTaxRate(null));
		yield put(OrderActions.setTaxRateLoaded(false));
		yield put(OrderActions.showTaxLoader(false));
	}
}

export function* validateAddress(action: ReturnType<typeof OrderActions.validateAddress>): any {
	try {
		yield put(UIActions.showLoader(true));
		const { data } = yield call(OrderService.getInstance().validateAddress, action.payload.taxPayload);
		if (data) {
			console.log('Validated address data: ', data);
			console.log('validateAddress action.payload: ', action.payload);

			// Dispatch the action to update the state with validated addresses
			yield put(OrderActions.setValidatedAddresses(data));
			yield put(
				UIActions.setSnackbarMessage({
					message: 'Valid addresses found',
					type: 'success',
				}),
			);

			// Call the callback after state updates
			action.payload.callback(true);
		}
		yield put(UIActions.showLoader(false));
	} catch (err: any) {
		console.error('validate address saga error', err);
		const message = err.response?.status === 404 ? 'Address not found' : err.message;
		yield put(
			UIActions.setSnackbarMessage({
				message: 'Failed to validate Address: ' + message,
				type: 'error',
			}),
		);
		yield put(OrderActions.setTaxRate(null));
		yield put(OrderActions.setTaxRateLoaded(false));
		yield put(UIActions.showLoader(false));
	}
}

export function* validateAddressList(action: ReturnType<typeof OrderActions.validateAddressList>): any {
	try {
		yield put(UIActions.showLoader(true));
		const { data } = yield call(OrderService.getInstance().validateAddressList, action.payload.taxPayload);
		if (data) {
			console.log('Validated address list data: ', data);
			console.log('validateAddressList action.payload: ', action.payload);

			yield put(OrderActions.setTaxRate(data));
			yield put(OrderActions.setTaxRateLoaded(true));

			// Dispatch the action to update the state with validated addresses
			yield put(OrderActions.setValidatedTaxResult(data));
			yield put(
				UIActions.setSnackbarMessage({
					message: 'Valid addresses found',
					type: 'success',
				}),
			);

			// Call the callback after state updates
			action.payload.callback();
		}
		yield put(UIActions.showLoader(false));
	} catch (err: any) {
		console.error('validate address list saga error', err);
		const message = err.response?.status === 404 ? 'Address not found' : err.message;
		yield put(
			UIActions.setSnackbarMessage({
				message: 'Failed to validate Address List: ' + message,
				type: 'error',
			}),
		);
		yield put(OrderActions.setTaxRate(null));
		yield put(OrderActions.setTaxRateLoaded(false));
		yield put(UIActions.showLoader(false));
	}
}

export function* getProduct(action: ReturnType<typeof OrderActions.getProduct>): any {
	try {
		yield put(UIActions.showLoader(true));
		const { data } = yield call(OrderService.getInstance().getProduct, action.payload);
		if (data) {
			yield put(OrderActions.setProduct(data));
		}
		yield put(UIActions.showLoader(false));
	} catch (err: any) {
		console.log('getProduct saga error', err);
		yield put(UIActions.setSnackbarMessage({ message: 'Product not loaded, please refresh page', type: 'error' }));
		yield put(UIActions.showLoader(false));
	}
}

export function* getCcPaymentToken(action: ReturnType<typeof OrderActions.getCcPaymentToken>): any {
	try {
		yield put(UIActions.showLoader(true));
		const { data } = yield call(OrderService.getInstance().getPaymentSessionToken, action.payload);
		if (data) {
			// yield put(OrderActions.setToken(data));
			// action.payload.callback()
			console.log('SESS TOKEN', data);

			//const sessionToken = encodeURIComponent(data);

			openLightbox(data);
			// const hostedPaymentUrl = config.invoicePayment.url + sessionToken;

			// window.open(hostedPaymentUrl, '_blank');
		}
		yield put(UIActions.showLoader(false));
	} catch (err: any) {
		console.log('getCcPaymentToken saga error', err);

		yield put(
			UIActions.setSnackbarMessage({
				message: 'Failed to get product: ' + err.message,
				type: 'error',
			}),
		);

		yield put(UIActions.showLoader(false));
	}

	function openLightbox(token: any) {
		var paymentFields = {
			ssl_txn_auth_token: token,
		};
		var callback = {
			// onError: function (error) {
			//     showResult("error", error);
			// },
			// onCancelled: function () {
			//     showResult("cancelled", "");
			// },
			// onDeclined: function (response) {
			//     showResult("declined", JSON.stringify(response, null, '\t'));
			// },
			onApproval: function (response: any) {
				console.log('APPROVAL!!' + JSON.stringify(response));
				//showResult("approval", JSON.stringify(response, null, '\t'));
			},
		};
		const w = window as any;
		const pwc: any = w.PayWithConverge;
		pwc.open(paymentFields, callback);

		return false;
	}
}
