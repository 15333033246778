import * as React from 'react';
import { BasePageStyles } from '../../hooks/styles';
import {
	Autocomplete,
	Box,
	Checkbox,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Paper,
	TextField,
	Theme,
	Tooltip,
	Typography,
	useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import dataList from '../../constants/dataList';
import DeleteIcon from '@mui/icons-material/Delete';
import NumberInput from '../../components/numberPicker';
import { Address, AddressModel } from './address.model';
import { Product } from '../product/product.type';
import { connect } from 'react-redux';
import { OrderActions } from '../../redux/actions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import { RootState } from '../../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { addOrderShippingItem, updateOrderShippingItem, removeOrderShippingItem } from './order-shipping-list.slice';
import { OrderShippingItemInterface, OrderShippingItem } from './order-shipping-item.model';

export interface ShippingAddressProps {
	listIndex: number;
	listLengthIsGreatThanTwo: boolean;
	paymentInputs: any;
	orderQuantity: number;
	enableSubmitOrder: boolean;
	setEnableSubmitOrder: (enableIt: boolean) => void;
	taxRate?: any;
	taxRateLoaded?: boolean;
	setTaxRateLoaded?: (loaded: boolean) => void;
	getTaxRate?: (obj: any) => void;
	product: Product;
	validateAddress: (payload: { callback: (isOpen: boolean) => void; taxPayload: any }) => void;
	shippingAddress: AddressModel;
	billingAddress: Address;
	isSameAsBilling: boolean;
	setIsSameAsBilling: (isChecked: boolean) => void;
}

const ShippingAddressComponent: React.FC<ShippingAddressProps> = React.memo(props => {
	const dispatch = useDispatch();
	const shippingList: Array<OrderShippingItem> = useSelector(
		(state: RootState) => state.orderShippingList.orderShippingList,
	);

	const classes = BasePageStyles();
	const zipRegex = new RegExp(/^\d{5}$/);
	const isFirstListItem = props.listIndex === 0;

	const [tooltipTitle, setTooltipTitle] = React.useState('Min 1');
	const isFirstRender = React.useRef(true);
	const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
	const [shippingAddressErrors, setShippingAddressErrors] = React.useState<Address>(new AddressModel());
	const [hasShippingAddressError, setHasShippingAddressError] = React.useState<boolean>(true);

	const isMounted = React.useRef(false);

	const [localAddress, setLocalAddress] = React.useState<Address>(shippingList[props.listIndex].shippingAddress);

	// useEffect to track component mount and unmount
	React.useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	const isTooltipOpen = () => {
		return !isFirstRender.current && (props.orderQuantity === 1 || props.orderQuantity === 2250);
	};

	const onQuantityChange = (newQty: number) => {
		if (newQty < 1) newQty = 1;
		if (newQty > 2250) newQty = 2250;
		const newShippingItem: OrderShippingItemInterface = { ...shippingList[props.listIndex] };
		newShippingItem.productList[0].qty = newQty; // We're only shipping Narcan for now
		dispatch(updateOrderShippingItem({ index: props.listIndex, newShippingItem: newShippingItem }));
		if (newQty === 1) setTooltipTitle('Min 1');
		else if (newQty === 2250) setTooltipTitle('Max 2250');
		if (isFirstRender.current) isFirstRender.current = false;
	};

	const checkIfAddressHasErrors = (newShippingAddressErrors: Address) => {
		for (const [key, value] of Object.entries(newShippingAddressErrors)) {
			if (value !== '') {
				setHasShippingAddressError(true);
				return;
			}
		}
		// No Error detected
		if (hasShippingAddressError) {
			setHasShippingAddressError(false);
		}
	};

	const handleAddressChange = (key: keyof Omit<Address, 'isValid' | 'errorMessage'>, newValue: string) => {
		if (!isMounted.current) {
			// If we're not mounted then we don't do anything.
			return;
		}

		// Address has changed and thus it needs to be revalidated and tax recalculated
		if (props.taxRateLoaded && props.setTaxRateLoaded && localAddress[key] !== newValue) {
			props.setTaxRateLoaded(false);
		}

		const newAddress: Address = { ...localAddress };
		if (props.shippingAddress.isValid) {
			// The change means validation needs to be reset
			newAddress.isValid = false;
		}
		newAddress[key] = newValue;

		const newErrors = { ...shippingAddressErrors };

		switch (key) {
			case 'address':
				if (newValue === '') {
					newErrors[key] = 'Address Line 1 is required';
				} else {
					newErrors[key] = '';
				}
				break;
			case 'city':
				if (newValue === '') {
					newErrors[key] = 'City is required';
				} else {
					newErrors[key] = '';
				}
				break;
			case 'state':
				if (newValue === '') {
					newErrors[key] = 'State is required';
				} else {
					newErrors[key] = '';
				}
				break;
			case 'zip':
				if (newValue === '') {
					newErrors[key] = 'ZIP Code is required';
				} else if (!zipRegex.test(newValue)) {
					newErrors[key] = 'ZIP Code is invalid';
				} else {
					newErrors[key] = '';
				}
				break;
			default:
			// optional input.  No possible errors
		}
		setShippingAddressErrors(newErrors);
		checkIfAddressHasErrors(newErrors);

		setLocalAddress(newAddress);
	};

	const handleUpdateListAddress = (newAddress: Address) => {
		const newItem: OrderShippingItemInterface = { ...shippingList[props.listIndex] };
		newItem.shippingAddress = { ...newAddress };
		dispatch(updateOrderShippingItem({ index: props.listIndex, newShippingItem: newItem }));
	};

	/*
		The local state is synchronized with the parent state when the input field loses focus (onBlur),
		reducing the frequency of state updates in the parent and, thus, re-renders.

		This prevents the "de-focus" on every character change.
	*/
	const handleOnBlur = () => {
		console.log('Local Address set to parent: ', localAddress);
		handleUpdateListAddress(localAddress);
	};

	/*
		TODO: Store the checkbox in the parent and pass it down.  This should fix the uncheck issue.
	*/
	const handleSameAsBillingChange = () => {
		if (props.isSameAsBilling) {
			props.setIsSameAsBilling(false);
			handleUpdateListAddress(localAddress);
		} else {
			props.setIsSameAsBilling(true);
			handleUpdateListAddress(props.billingAddress);
		}
	};

	const handleDeleteShippingAddress = () => {
		dispatch(removeOrderShippingItem(props.listIndex));
	};

	return (
		<>
			<Paper
				className={classes.paymentCard}
				elevation={1}
				sx={{
					gridTemplateColumns: 'repeat(2, 1fr)',
					gridTemplateRows: 'auto',
				}}
			>
				<Box sx={{ gridRow: '1', gridColumn: 'span 2' }} style={{ height: '30px' }}>
					<Grid container>
						{!isFirstListItem && (
							<Grid xs={1}>
								<IconButton aria-label="delete" aria-role="button" onClick={handleDeleteShippingAddress}>
									<DeleteIcon />
								</IconButton>
							</Grid>
						)}
						<Grid xs={5} lg={3}>
							<h3>Shipping Address</h3>
						</Grid>
						{isFirstListItem && (
							<Grid xsOffset={1} xs={6} lgOffset={3}>
								<FormControlLabel
									control={
										<Checkbox
											inputProps={{ 'aria-label': 'controlled' }}
											size="small"
											checked={props.isSameAsBilling}
											onChange={handleSameAsBillingChange}
											aria-role="checkbox"
										/>
									}
									label="Same as Billing Address"
								/>
							</Grid>
						)}
					</Grid>
				</Box>
				<TextField
					label="Address Line 1"
					variant="outlined"
					sx={{ gridRow: '2', gridColumn: '1' }}
					value={localAddress.address}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleAddressChange('address', event.target.value);
					}}
					onBlur={handleOnBlur}
					error={shippingAddressErrors.address ? true : false}
					helperText={shippingAddressErrors.address ? shippingAddressErrors.address : ''}
					required
					disabled={isFirstListItem && props.isSameAsBilling}
					aria-role="textbox"
				/>
				<TextField
					label="Address Line 2"
					variant="outlined"
					sx={{ gridRow: '2', gridColumn: '2' }}
					value={localAddress.address2}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleAddressChange('address2', event.target.value);
					}}
					onBlur={handleOnBlur}
					disabled={isFirstListItem && props.isSameAsBilling}
					aria-role="textbox"
				/>
				<Box sx={{ gridRow: '3', gridColumn: '1' }}>
					<Grid container spacing={1}>
						<Grid xs={4}>
							<TextField
								label="City"
								variant="outlined"
								value={localAddress.city}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									handleAddressChange('city', event.target.value);
								}}
								onBlur={handleOnBlur}
								error={shippingAddressErrors.city ? true : false}
								helperText={shippingAddressErrors.city ? shippingAddressErrors.city : ''}
								required
								disabled={isFirstListItem && props.isSameAsBilling}
								aria-role="textbox"
							/>
						</Grid>
						<Grid xs={4}>
							<Autocomplete
								disablePortal
								options={dataList.StateCodes}
								getOptionLabel={(option: any) => `${option.value}(${option.label})`}
								value={
									localAddress.state ? dataList.StateCodes.find((code: any) => code.label === localAddress.state) : null
								}
								onChange={(event, newValue) => {
									handleAddressChange('state', newValue?.label || '');
								}}
								onBlur={handleOnBlur}
								renderInput={params => (
									<TextField
										{...params}
										variant="outlined"
										label="State"
										error={shippingAddressErrors.state ? true : false}
										helperText={shippingAddressErrors.state || ''}
										required
										aria-role="textbox"
									/>
								)}
								disabled={isFirstListItem && props.isSameAsBilling}
							/>
						</Grid>
						<Grid xs={4}>
							<TextField
								label="Zip Code"
								variant="outlined"
								value={localAddress.zip}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									handleAddressChange('zip', event.target.value);
								}}
								onBlur={handleOnBlur}
								error={shippingAddressErrors.zip ? true : false}
								helperText={shippingAddressErrors.zip ? shippingAddressErrors.zip : ''}
								required
								disabled={isFirstListItem && props.isSameAsBilling}
								aria-role="textbox"
							/>
						</Grid>
					</Grid>
				</Box>

				<Grid container spacing={1}>
					{props.listLengthIsGreatThanTwo && (
						<>
							<Grid lg={4} style={{ display: isXs ? 'none' : 'block' }}>
								{!isXs && <p>Qty to this Address:</p>}
							</Grid>
							<Grid md={6} lg={4}>
								<Tooltip title={tooltipTitle} open={isTooltipOpen()} PopperProps={{ disablePortal: true }} arrow>
									<NumberInput
										aria-label="Quantity Input"
										min={1}
										max={2250}
										value={props.orderQuantity}
										onChange={(event, newValue) => {
											onQuantityChange(newValue || 0);
										}}
										onInputChange={event => {
											onQuantityChange(parseInt(event.target.value || '0'));
										}}
										endAdornment={
											<InputAdornment position="end" sx={{ marginLeft: '-35px', marginRight: '18px' }}>
												bx
											</InputAdornment>
										}
										aria-role="textbox"
									/>
								</Tooltip>
							</Grid>
						</>
					)}
					{!props.listLengthIsGreatThanTwo && <Grid md={6} lg={8}></Grid>}
					<Grid md={6} lg={4}>
						<Box sx={{ gridRow: '3', gridColumn: 'span 2', display: 'flex', justifyContent: 'center' }}>
							{props.shippingAddress.isValid && (
								<>
									<CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
									<Typography sx={{ color: 'green' }}>Validated</Typography>
								</>
							)}
							{!props.shippingAddress.isValid && props.shippingAddress.errorMessage && (
								<>
									<CancelIcon sx={{ color: 'red', mr: 1 }} />
									<Tooltip title={props.shippingAddress.errorMessage} arrow>
										<Typography color="error">Invalid Address</Typography>
									</Tooltip>
								</>
							)}
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</>
	);
});

const mapStateToProps = (state: any) => ({
	taxRate: state.order.taxRate || 0,
	taxRateLoaded: state.order.taxRateLoaded || false,
	isTaxLoading: state.order.isTaxLoading,
	isCaptchaValid: state.captcha.isCaptchaValid || false,
});

const mapDispatchToProps = (dispatch: any) => ({
	getTaxRate: (payload: any) => dispatch(OrderActions.getTaxRate(payload)),
	setTaxRateLoaded: (payload: boolean) => dispatch(OrderActions.setTaxRateLoaded(payload)),
	validateAddress: (payload: any) => dispatch(OrderActions.validateAddress(payload)),
	getCcToken: (payload: any) => dispatch(OrderActions.getCcPaymentToken(payload)),
	clearTaxRate: () => dispatch(OrderActions.clearTaxRate()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddressComponent);
